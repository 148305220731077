import { useRef, useEffect } from 'react';
import gsap from 'gsap/all';
import { useRouter } from 'next/navigation';

//* Styles
import InfoBuildingsStyle from './style';

//* Helpers
import numberSpecialSymbol from '@/modules/apartments-individual/components/Calculator/helpers-calculator';

//* HOO'C
import { withLanguageContext, withUIContext } from '@/context';

//* Component
import Text from '@/components/common/Text';

const InfoBuildings = ({ dataG, name, slug, floors_count, min_meter_price, min_area, className, hover, translate, winWidth, isTouch, selectedLang }) => {
	//! Ref
	const infoRef = useRef();
	const wrapperRef = useRef();
	const circleRef = useRef();

	//! router
	const router = useRouter();

	function animationContentOpen(reverse) {
		if (circleRef.current && infoRef.current) {
			const sizeCircleX = circleRef.current.getBoundingClientRect().x;
			const sizeCircleWidth = circleRef.current.clientWidth;
			const sizeInfo = infoRef.current.clientWidth;
			//! orintation
			let originContent;
			let orintationWithWinWidth = winWidth >= 1440;

			//! default
			infoRef.current.style.cssText = `
			left:${orintationWithWinWidth ? sizeCircleWidth : 'auto'};
			right:${orintationWithWinWidth ? 0 : 'auto'};
			`;

			//! no size width content
			if (sizeCircleX + sizeInfo > winWidth) {
				originContent = orintationWithWinWidth ? '100% 100%' : '100% 0%';
				infoRef.current.style.cssText = `
				left:auto;
				right:${orintationWithWinWidth ? sizeCircleWidth + 'px' : 0};
				`;
			} else {
				originContent = orintationWithWinWidth ? '0 90%' : '0% 0%';
			}
			gsap.to(infoRef.current, { scale: reverse ? 0 : 1, duration: 0.3, transformOrigin: originContent, ease: 'power2.out' });
			gsap.to(wrapperRef.current, { zIndex: reverse ? 1 : 10 });
		}
	}

	//! enter touch
	function mouseEnterInfo() {
		if (!isTouch) {
			if (hover) {
				gsap.to(dataG, { opacity: 0.5, duration: 0.3, ease: 'power2.out', cursor: 'pointer' });
				circleRef.current.classList.add('hovered');
			}

			animationContentOpen();
		}
	}

	//! leave touch
	function mouseLeaveInfo() {
		if (!isTouch) {
			circleRef.current.classList.remove('hovered');
			gsap.to(dataG, { opacity: 0, duration: 0.3, ease: 'power2.out', cursor: 'default' });

			animationContentOpen(true);
		}
	}

	//! create enter leave
	useEffect(() => {
		if (dataG) {
			dataG.addEventListener('mousemove', () => {
				mouseEnterInfo();
			});
			dataG.addEventListener('mouseleave', () => {
				mouseLeaveInfo();
			});
			dataG.addEventListener('click', () => {
				if (slug) {
					router.push(`/${selectedLang}/buildings/${slug}`);
				}
			});
		}
	});

	return (
		<InfoBuildingsStyle
			ref={wrapperRef}
			className={className || ''}>
			<div className='block-wrapper'>
				<div
					ref={circleRef}
					className='circle'>
					<Text className={'h h5 font-montserrat-semibold name'}>{name}</Text>
				</div>
			</div>
			{floors_count && min_meter_price && min_area && (
				<div
					ref={infoRef}
					className='wrapper-info'>
					<div className='inner-wrapper-info'>
						<Text className={'info-price font-montserrat-medium p2'}>
							{'-'} {floors_count} {translate('floors')}
						</Text>
						<Text className={'info-price font-montserrat-medium p2'}>
							- {translate('priceFrom')} {numberSpecialSymbol({ symbol: '.', number: min_meter_price })} {translate('currency')}
						</Text>
						<Text className={'info-price font-montserrat-medium p2'}>
							- {translate('spaceFrom')} {min_area} {translate('meter')}
							<sup>2</sup>{' '}
						</Text>
					</div>
				</div>
			)}
		</InfoBuildingsStyle>
	);
};

export default withUIContext(withLanguageContext(InfoBuildings, ['translate', 'selectedLang']), ['winWidth', 'isTouch']);
